<template>
  <div class="dcr-record-warp">
    <div class="dcr-record-title">DCR记录</div>
    <div class="dcr-record-data">
      <div class="dcr-submit-num commonSty">
        累计提交(条)
        <p>{{ record_info.submit_num | numThousand }}</p>
      </div>
      <div class="dcr-dealed-num commonSty">
        已处理(条)
        <p>{{ record_info.dealed_num | numThousand }}</p>
      </div>
      <div class="dcr-no-deal-num commonSty">
        待处理(条)
        <p>{{ record_info.no_deal_num | numThousand }}</p>
      </div>
      <div class="dcr-deal-rate commonSty">
        处理率
        <p>{{ record_info.deal_rate }}</p>
      </div>
      <div class="dcr-month-submit commonSty">
        本月提交(条)
        <p>{{ record_info.month_submit | numThousand }}</p>
      </div>
    </div>

    <!-- 表格数据  -->
    <div class="dcr-record-content-warp">
      <div class="dcr-record-content-tab" v-if="role === 1">
        <p :class="{ tab_active: activeTab === 0 }" @click="handleTabClick(0)">全部DCR</p>
        <p :class="{ tab_active: activeTab === 1 }" @click="handleTabClick(1)">待审批DCR</p>
      </div>

      <!-- 表格筛选区域 -->
      <div v-if="filterList.length > 0" class="dcr-record-filter">
        <div v-for="(item, index) in filterList" :key="index" class="dcr-record-filter-item">
          {{ getFlitersTitle(item.name) }}: {{ item.label }}
          <i class="el-icon-close" @click="deleteFilterItem(item.name)"></i>
        </div>
        <el-button
          v-if="filterList.length"
          class="datalist-title-button"
          type="text"
          @click="deleteAllFilter"
        >
          重置
        </el-button>
      </div>

      <div class="dcr-record-content-data" v-show="!requestData">
        <eyao-table
          v-show="!requestData"
          ref="table"
          :table-data="tableData"
          :total-num="totalNum"
          @size-change="handleSizeChange"
          @current-change="handleCurrentChange"
          @sort-change="hanldeSortChange"
          :select-page-size="10"
        >
          <el-table-column
            v-for="column in configList"
            :key="`${$route.name}-${column.property}`"
            :label="column.title"
            :prop="column.property"
            :width="column.width"
            :min-width="column['min-width']"
            :sortable="column.sort && 'custom'"
          >
            <template slot="header" slot-scope="scope">
              <!-- 表头 -->
              <eyao-table-search-header
                v-if="column.showIcon"
                :form-type="column.showIcon"
                :property="column.property"
                :title="column.title"
                :scope="scope"
                :options="valueOpts[column.property]"
                @search="searchOpts"
              ></eyao-table-search-header>
              <!-- 无icon -->
              <template v-else>{{ column.title }}</template>
            </template>

            <template slot-scope="scope">
              <!-- 涉及时间字段，format值 -->
              <template v-if="column.format">
                {{ scope.row[column.property] | getFormatTime }}
              </template>

              <!-- 状态 -->
              <template v-else-if="column.property === 'deal_status'">
                <div class="data-status">
                  <!-- 已提交 -->
                  <i
                    v-if="scope.row.status === 1 && scope.row.verification === 3"
                    :style="{ color: '#FA9600', background: '#FA9600' }"
                  />
                  <!-- 处理中 -->
                  <i
                    v-else-if="scope.row.status === 2 && scope.row.verification === 3"
                    :style="{ color: '#1664FF', background: '#1664FF' }"
                  />
                  <!-- 已完成 -->
                  <i
                    v-else-if="scope.row.status === 3"
                    :style="{ color: '#23b812', background: '#23b812' }"
                  />
                  <!-- 处理中(待验证) -->
                  <i
                    v-else-if="scope.row.status === 2 && scope.row.verification === 1"
                    :style="{ color: '#1664FF', background: '#1664FF' }"
                  />
                  <!-- 处理中(待确认) -->
                  <i
                    v-else-if="scope.row.status === 2 && scope.row.verification === 2"
                    :style="{ color: '#1664FF', background: '#1664FF' }"
                  />

                  {{ scope.row[column.property] | handleStatus }}
                </div>
              </template>
              <template v-else>{{ scope.row[column.property] }}</template>
            </template>
          </el-table-column>

          <!-- 操作列  -->
          <el-table-column width="100" fixed="right" label="操作">
            <div slot-scope="scope" class="opt-column">
              <span type="text" @click="handleViewDetail(scope.row)">查看</span>
            </div>
          </el-table-column>
          <template v-slot:empty>
            <div class="search-no-data" v-if="totalNum === 0 && !requestData">
              <img
                src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
                alt="暂无符合条件的DCR,请重新查询"
              />
              暂无符合条件的DCR,请重新查询
            </div>
          </template>
        </eyao-table>

        <!-- <div v-if="totalNum === 0 && !requestData" class="search-no-data">
          <img
            src="https://baike-med-1256891581.file.myqcloud.com/drugs_admin/20111202/6fd06465-d653-4f86-963e-cb5196e49caf.png"
            alt="暂无符合条件的DCR,请重新查询"
          />
          暂无符合条件的DCR,请重新查询
        </div> -->
      </div>
      <div class="listLoading" v-if="requestData">
        <img src="@/assets/loading.gif" alt="loading" />
      </div>
    </div>
  </div>
</template>

<script>
import eyaoTable from '@/components/table';
import eyaoTableSearchHeader from '@/components/table-search-header';
import configList from './config';
import configLabel from './config/configLabel';
import { dateFormat } from '@/utils/util';
import { QueryStatisticalOfDcr, QueryDcrList, IsVip } from '@/api/dcr';

export default {
  components: { eyaoTable, eyaoTableSearchHeader },
  filters: {
    // 数字千分位隔开
    numThousand(value) {
      return Number(value).toLocaleString();
    },
    getFormatTime(value) {
      if (value && !value.startsWith('0')) {
        return dateFormat(new Date(value), 'YYYY-MM-dd');
      }
      return '';
    },
    handleStatus(val) {
      switch (val) {
        case 1:
          return '已提交';
        case 2:
          return '处理中';
        case 3:
          return '已完成';
        case 21:
          return '处理中(待验证)';
        case 22:
          return '处理中(待确认)';
      }
    },
  },
  data() {
    return {
      record_info: {
        submit_num: 0,
        dealed_num: 0,
        no_deal_num: 0,
        deal_rate: '0%',
        month_submit: 0,
      },
      activeTab: 0,
      requestData: true,
      // 表格数据
      tableData: [],
      totalNum: 0,
      configList: [],
      configLabel: [],
      currentDataPage: 1,
      pageDataSize: 10,
      propertyName: ['type', 'data_type', 'resolution'],
      // 筛选
      filterObject: {},
      filterList: [],
      // 时间排序
      orderTime: {},
      isVipUser: false,
    };
  },
  computed: {
    // 角色权限判定
    role() {
      const newRole = JSON.parse(localStorage.getItem('role'));
      // 页面刷新时
      if (newRole) {
        this.$store.commit('user/updateRole', newRole);
      } else {
        return this.$store.state.user.role;
      }
      return newRole;
    },
    // 筛选条件
    valueOpts() {
      const type = [
        { label: '新增', value: '1' },
        { label: '变更', value: '2' },
        { label: '删除(失效)', value: '3' },
      ];
      /* eslint-disable */
      const data_type = [
        { label: '医疗机构', value: '2' },
        { label: '医疗专业人士', value: '1' },
      ];
      const deal_status = [
        { value: 1, label: '已提交' },
        { value: 2, label: '处理中' },
        { value: 3, label: '已完成' },
      ];
      /* eslint-disable */
      const resolution = [
        { label: '接受', value: '1' },
        { label: '拒绝', value: '2' },
      ];
      // 管理端普通用户（内部运维），只能看到deal_status前3个
      // 客户端普通用户和非购买高级版，只能看到deal_status前3个
      if (
        (this.systemEnv === 1 && this.role === 1) ||
        (this.systemEnv === 2 && this.isVipUser && this.role === 1)
      ) {
        const arr = [
          { value: 21, label: '处理中(待验证)' },
          { value: 22, label: '处理中(待确认)' },
        ];
        deal_status.push(...arr);
      }
      return { type, data_type, deal_status, resolution };
    },
    // 判断管理端还是客户端
    systemEnv() {
      // systemEnv为1表示管理端，2表示客户端
      return Number(localStorage.getItem('systemEnv'));
    },
    // 用户名
    userName() {
      const usr = JSON.parse(localStorage.getItem('userName'));
      if (!this.$store.state.user.userName && usr) {
        this.$store.commit('user/updateUserName', usr);
      } else {
        return this.$store.state.user.userName;
      }
      return usr;
    },
  },
  watch: {
    $route(to) {
      if (to.name === 'dcr-record') {
        this.init();
      }
    },
    role(newval) {
      if (newval === 3) {
        this.$message.error('无权限进入');
        this.$router.push('/dashboard/home');
      }
    },
  },
  mounted() {
    this.init();
  },
  methods: {
    init() {
      this.requestData = true;
      this.configList = configList;
      this.configLabel = configLabel;
      if (this.systemEnv === 2 && this.role === 1) {
        this.getVipUser();
      }
      this.getStatisticData();
      this.handleTabClick();
    },

    // 是否购买了高级版用户
    getVipUser() {
      IsVip({ service_name: 'dcr' })
        .then((res) => {
          this.isVipUser = res.is_vip;
        })
        .catch((err) => {
          this.$message.error(err.message);
        });
    },

    // 获取统计数据
    getStatisticData() {
      QueryStatisticalOfDcr()
        .then((res) => {
          if (res) {
            this.record_info = {
              submit_num: res.submit_total,
              dealed_num: res.processed_total,
              no_deal_num: res.pending_total,
              deal_rate: Math.round(res.percent * 10000) / 100 + '%',
              month_submit: res.submit_month,
            };
          }
        })
        .catch((err) => {
          this.$message.error(`${err.message}`);
        });
    },

    handleTabClick(tab = 0) {
      this.activeTab = tab;
      this.pageDataSize = 10;
      this.currentDataPage = 1;
      this.filterInit();
    },

    getDcrList() {
      console.log(this.filterObject);
      const { create_user, data_type, deal_status, resolution, type } = this.filterObject;
      let status = 0;
      let verification = 0;
      if (deal_status?.value && deal_status.value === 1) {
        status = 1;
        verification = 3;
      } else if (deal_status?.value && deal_status.value === 2) {
        status = 2;
        verification = 3;
      } else if (deal_status?.value && deal_status.value === 3) {
        status = 3;
        verification = 0;
      } else if (deal_status?.value && deal_status.value === 21) {
        status = 2;
        verification = 1;
      } else if (deal_status?.value && deal_status.value === 22) {
        status = 2;
        verification = 2;
      }
      let createUser = '';
      console.log(this.role, this.userName);
      if (this.role !== 1) {
        createUser = this.userName;
      } else if (this.role === 1 && create_user) {
        // eslint-disable-line
        createUser = create_user.value;
      }

      this.requestData = true;
      const params = {
        search_type: Number(this.activeTab + 1),
        index: this.currentDataPage,
        page_size: this.pageDataSize,
        dcr_type: type?.value ? Number(type.value) : 0,
        data_type: data_type?.value ? Number(data_type.value) : 0,
        status,
        verification,
        create_by: createUser,
        resolution: resolution?.value ? Number(resolution.value) : 0,
        order_type: this.orderTime.createTime ? Number(this.orderTime.createTime) : 2, // 1-正序，2-倒序
      };
      QueryDcrList(params)
        .then((res) => {
          console.log(res);
          if (res?.data) {
            const dataList = res.data.map((item) => JSON.parse(item));
            this.totalNum = res.total_num;
            // 处理label
            this.dealDataLabel(dataList);
            // 处理status值
            this.dealStatus(dataList);
            this.tableData = dataList;
            console.log(dataList);
            setTimeout(() => {
              this.requestData = false;
            }, 500);
          }
        })
        .catch((err) => {
          this.$message.error(`${err.message}`);
          this.requestData = false;
        });
    },

    dealStatus(list) {
      // `status` 'DCR状态, 1-待处理,2-处理中,3-已完成',
      // `verification` 'DCR验证状态, 1-待验证,2-待确认,3-无效(没购买)'
      list.forEach((ele) => {
        if (ele.status === 1 && ele.verification === 3) {
          ele.deal_status = 1; // 已提交
        }
        if (ele.status === 2 && ele.verification === 3) {
          ele.deal_status = 2; // 处理中
        }
        if (ele.status === 2 && ele.verification === 1) {
          ele.deal_status = 21; // 处理中(待验证)
        }
        if (ele.status === 2 && ele.verification === 2) {
          ele.deal_status = 22; // 处理中(待确认)
        }
        if (ele.status === 3) {
          ele.deal_status = 3; // 已完成
        }
      });
    },

    // 跳转DCR详情
    handleViewDetail(row) {
      console.log(row, '查看');
      const query = {
        id: row.id,
      };
      console.log(query);
      const routerData = this.$router.resolve({
        path: '/dashboard/dcrRecord-detail',
        query,
      });
      window.open(decodeURIComponent(routerData.href), '_blank');
    },

    // 审批
    handleCheck(row) {
      console.log(row, '审批');
    },

    // 搜索表头关键字
    searchOpts(property, val, formType, label) {
      console.log(property, val, formType, label);
      if (formType === 'input') {
        this.filterChange({ [property]: { label: val, value: val, formType } });
      } else {
        this.filterChange({ [property]: { label, value: val, formType } });
      }
    },

    filterChange(filter) {
      Object.keys(filter).forEach((key) => {
        this.filterObject[key] = filter[key];
      });
      this.updateFilterList();
      this.getDcrList();
    },

    // 筛选信息初始化
    filterInit() {
      this.filterObject = this.getStorage() || {};
      this.updateFilterList();
      this.getDcrList();
    },

    updateFilterList() {
      const list = [];
      Object.keys(this.filterObject).forEach((key) => {
        if (JSON.stringify(this.filterObject[key]) !== '{}') {
          list.push({ name: key, label: this.filterObject[key].label });
        }
      });
      this.filterList = list;
      this.setStorage();
    },

    // 展示筛选条件的标题
    getFlitersTitle(property) {
      let title = '';
      this.configList.forEach((item) => {
        if (property === item.property) title = item.title;
      });
      return title;
    },

    deleteFilterItem(key) {
      this.filterObject[key] = {};
      this.$refs.table.clearFilter([key]);
      this.updateFilterList();
      this.getDcrList();
    },

    deleteAllFilter(flag = true) {
      localStorage.removeItem('dcrRecord-filter');
      this.filterList = [];
      this.filterObject = {};
      this.$refs.table.clearFilter();
      if (flag) this.getDcrList();
    },

    setStorage() {
      const filterData = this.getStorage();
      filterData['dcr-record'] = this.filterObject;
      localStorage.setItem('dcrRecord-filter', JSON.stringify(this.filterObject));
    },
    getStorage() {
      const str = localStorage.getItem('dcrRecord-filter');
      return str ? JSON.parse(str) : {};
    },

    // pagination操作
    handleCurrentChange(val) {
      console.log(val);
      this.currentDataPage = val;
      this.getDcrList();
    },
    handleSizeChange(val) {
      console.log(val);
      this.pageDataSize = val;
      this.getDcrList();
    },

    // 时间排序
    hanldeSortChange(column) {
      console.log(column);
      if (column.order === 'descending') {
        this.orderTime = { createTime: 2 };
      } else if (column.order === 'ascending') {
        this.orderTime = { createTime: 1 };
      }
      this.getDcrList();
    },

    // 处理列表中下拉菜单的label值
    dealDataLabel(list) {
      const reg = /^[0-9a-zA-Z]/;
      list.forEach((ele) => {
        Object.keys(ele).forEach((key) => {
          if (this.propertyName.includes(key) && reg.test(ele[key])) {
            ele[key] = this.getCodeLabel(key, ele[key]);
          }
        });
      });
    },

    getCodeLabel(key, value) {
      const valObj = this.configLabel.filter((item) => item.property === key);
      let valArr = [];
      if (JSON.stringify(valObj) !== '{}') {
        valArr = valObj[0].value;
      }
      let res = '';
      if (valArr.length > 0) {
        const arr = valArr.filter((item) => item.code == value);
        if (arr.length > 0) {
          res = arr[0].label;
        }
      }
      return res;
    },
  },
};
</script>
<style lang="scss" scoped>
@import './index.scss';
</style>

export default [
  {
    title: 'DCR编码',
    width: 200,
    property: 'dcr_code',
    // showIcon: 'input',
  },
  {
    title: 'DCR类型',
    width: 120,
    property: 'type',
    showIcon: 'select',
  },
  {
    title: '修改数据',
    'min-width': 300,
    property: 'name',
  },
  {
    title: '数据类型',
    width: 120,
    property: 'data_type',
    showIcon: 'select',
  },
  {
    title: '处理状态',
    width: 140,
    property: 'deal_status',
    showIcon: 'select',
  },
  {
    title: '处理结果',
    width: 120,
    property: 'resolution',
    showIcon: 'select',
  },
  {
    title: '创建人',
    width: 120,
    property: 'create_user',
    showIcon: 'input',
  },
  {
    title: '创建日期',
    width: 120,
    property: 'create_time',
    format: true,
    sort: true,
  },
];

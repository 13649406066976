export default [
  {
    title: 'DCR类型',
    property: 'type',
    value: [
      { label: '新增', code: '1' },
      { label: '变更', code: '2' },
      { label: '删除(失效)', code: '3' },
    ],
  },
  {
    title: '数据类型',
    property: 'data_type',
    value: [
      { label: '医疗机构', code: '2' },
      { label: '医疗专业人士', code: '1' },
    ],
  },
  // {
  //   title: '处理状态',
  //   property: 'deaL_status',
  //   value: [
  //     { code: '1', label: '已提交' },
  //     { code: '2', label: '处理中' },
  //     { code: '3', label: '已完成' },
  //     { code: '21', label: '处理中(待验证)' },
  //     { code: '22', label: '处理中(待确认)' },
  //   ],
  // },
  {
    title: '处理结果',
    property: 'resolution',
    value: [
      { label: '接受', code: '1' },
      { label: '拒绝', code: '2' },
      { label: '-', code: '3' },
    ],
  },
];
